import {FETCH_EXHIBITOR_SEARCH_FAILURE, FETCH_EXHIBITOR_SEARCH_REQUEST, FETCH_EXHIBITOR_SEARCH_SUCCESS, SET_EXHIBITOR_SEARCH_TERM, SET_SAVE_EXHIBITOR_SEARCH_RESULTS} from "store/actions/actionTypes";

const initialState = {
  results: [],
  isFetching: false,
  error: null,
  hasMore: true,
  term: '',
  selectedFilter: 0,
  saveResults: false
};

const exhibitorSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EXHIBITOR_SEARCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        results: action.results,
        term: action.term,
        selectedFilter: action.selectedFilter,
        saveResults: false
      };
    case FETCH_EXHIBITOR_SEARCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        results: action.payload,
        hasMore: action.hasMore,
        term: action.term,
        selectedFilter: action.selectedFilter
      };
    case FETCH_EXHIBITOR_SEARCH_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching || false,
        error: action.payload
      };
    case SET_EXHIBITOR_SEARCH_TERM:
      return {
        ...state,
        term: action.payload
      }
    case SET_SAVE_EXHIBITOR_SEARCH_RESULTS:
      return {
        ...state,
        saveResults: action.payload
      }
    default:
      return state;
  }
};

export default exhibitorSearchReducer;