import {GET_SESSIONS} from "store/actions/actionTypes"

const sessionsReducer = (state = {}, action) =>{
  if(action.type === GET_SESSIONS){
    let sessions = null;
    if(action.payload){
      //this way, it will be easier to iterate through the sessions, as it will be a list.
      // but have to access the sessions as : state.sessions.sessions
      sessions = {"sessions": action.payload}; 
    }
    return {...state, ...sessions};
  }
  else return state;
}

export default sessionsReducer;