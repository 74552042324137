import _ from 'lodash';
import {GET_BOOTHS_BY_MAP, GET_CURRENT_SHOW, GET_EXHIBITORS, GET_SELECTED_BOOTH, SET_SELECTED_BOOTH, SET_SELECTED_EXHIBITORS, SET_SELECTED_MAP} from 'store/actions/actionTypes';

const showReducer = (state = {}, action) => {
  switch (action.type) {

    /* 
    * GET_BOOTHS_BY_SHOW payload (state object) structure:
    {
      showId: {
        public: "true"
        showEndDate: "2022-04-04"
        showName: "Crypto 2022"
        showStartDate: "2022-04-01"
        _id: "a025j000003FZYMAA4"
        booths: {
          boothId: {
            "boothArea": null,
            "boothWidth": "20",
            "boothNumber": "A11",
            "accountId": null,
            "exhibitorName": null,
            "showId": "a025j000003FZYMAA4",
            "boothCY": "10",
            "boothCX": "130",
            "boothLength": "30",
            "boothName": "00064",
            "_id": "a005j000007ZlIIAA0"
          }
        }
      }
    }
    */
    case GET_CURRENT_SHOW:
      var show = null;
      let mapList = null;
      if (action.payload !== undefined){
        show = action.payload.show;
        mapList = action.payload.mapList;
        const categories = action.payload.categories || [];
        show = _.assign(show, {mapList, categories});
      }
      return {
        ...state, ...show
      }
    case GET_BOOTHS_BY_MAP:
      if(action.payload !== undefined){
        show = {};
        let maps = state?.maps || {};
        var map = action.payload.map;
        var booths = _.mapKeys(action.payload.booths, '_id');
        map = {map: _.assign(map, {booths})};

        // add map data inside maps object with map id as key.
        maps = _.assign(maps, _.mapKeys(map, '_id'));
        show = _.assign(show, map);
        show = _.assign(show, {maps: maps});
      }
      return {
        ...state,
        ...show
      }

    case GET_EXHIBITORS:
      if(action.payload === null){
        let show = null
        if(state !== undefined){
          show = _.assign({exhibitors: null});
        }
        else{
          show = _.assign({ehibitors: null});
        }
        return{
          ...state, ...show
        }
      }
      if(action.payload !== undefined){
        var exhibitors = action.payload.exhibitors
        exhibitors = _.mapKeys(exhibitors, '_id');

        /* If booth data has already been loaded then put the exhibitor
          in the following format: 
          * show -> showId -> exhibitors.
        */
        if (state !== undefined) {
          show = state;
          show = _.assign(show, {exhibitors});
          return {...state, ...show}
        }
        show = _.assign(show, {exhibitors})
        return {
          ...state, ...show
        }
      }
      return state
    case SET_SELECTED_MAP:
      let mapId = action.payload.mapId;
      return {...state, ...{selectedMapId: mapId}};
    case SET_SELECTED_BOOTH:
      let boothId = action.payload.boothId;
      return {...state, ...{selectedBoothId: boothId}};
    case GET_SELECTED_BOOTH:
      if(action.payload){
        var booth = action.payload.boothData;
        var exhibitor = action.payload.exhibitor;
        return {
          ...state, 
          ...{
            selectedBooth: {booth, exhibitor}
          }
        }
      }
      return state;
    case SET_SELECTED_EXHIBITORS:
      if(action.payload){
        const exhibitor = action.payload.exhibitors;
        const booth = action.payload.booth;
        return {
          ...state, ...{
            selectedBooth: {
              booth,
              exhibitor
            }
          }
        }
      }
      return state;
    default:
      return state;
  }
}
export default showReducer;