import React from "react";
import {Nav} from "react-bootstrap";
import {NavLink as RouterNavLink} from 'react-router-dom';
import AuthNavigation from "./AuthNavigation";
import {getShowAbbreviation} from "common/index";
import {validateApprovedExhibitorUser, validateCurrentUser} from "components/utils";
import {useSelector} from "react-redux";
import GoogleTranslate from "components/GoogleTranslate";
import { showNavConfig } from '../../common/menuConfig';  

const NavLink = ({ eventKey, end, children, external, link, to }) => (
  <Nav.Item key={eventKey}>
    {external ? (
      <a eventkey={eventKey} className="nav-link" href={link} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    ) : (
      <RouterNavLink eventkey={eventKey} className="nav-link" to={to} end={end}>
        {children}
      </RouterNavLink>
    )}
  </Nav.Item>
);

const NavLinks = ({setNavExpanded, showHamburgerMenu}) => {
  const showAbbr = getShowAbbreviation();
  const user = useSelector(state => state.currentUser);
  const showId = useSelector((state) => state.show?._id);
  const showUnpublished = useSelector(state => state.show?.showUnpublished) || false;
  const isFloorplanEnabled = useSelector(state => state.show?.isFloorplanEnabled || false);
  const isAttendeeRegistrationEnabled = useSelector(state => state.show?.isAttendeeRegistrationEnabled || false); 
  const isConferenceEnabled = useSelector(state => state.show?.isConferenceEnabled || false);
  const enableLanguageSelector = useSelector((state) => state.show?.enableLanguageSelector || false);

  const handleAuthButtonClick = (e) => {
    e.stopPropagation();
  }
  const handleMobileNavClose = () => {
    setNavExpanded(false);
  } 

  const showConfig = showNavConfig[showAbbr] || showNavConfig.DEFAULT;

  // Function to determine if a link should be rendered based on its condition
  const shouldRenderLink = (condition) => {
    switch (condition) {
      case 'Floorplan': 
        return isFloorplanEnabled;
      case 'AttendeeRegistration': 
        return isAttendeeRegistrationEnabled;
      case 'Conference': 
        return isConferenceEnabled;
      case 'isExhibitorUser': 
        return validateApprovedExhibitorUser(user, showId);
      default: 
        return true;
    }
  };

  // Sort and filter links based on the 'order' and 'condition' properties
  const sortedLinks = showConfig.links
    .filter(link => !link.condition || shouldRenderLink(link.condition))
    .sort((a, b) => a.order - b.order)
    .map((item, index) => (
      <NavLink
        key={index + 20}
        eventKey={index + 20}
        external={item.external}
        link={item.link}
        to={item.to}
        end
      >
        {item.label}
      </NavLink>
    ));
  
  const commonLinks = [
    // // Dynamic show-specific menus
    // ...sortedLinks,

    // <NavLink to={`/${showAbbr}`} eventKey="4" end key={4}>Home</NavLink>,
    // Other links like Floorplan, Exhibitors, etc.
    // (isFloorplanEnabled) && ( 
    //   <>
    //     <NavLink to={`/${showAbbr}/floorplan`} eventKey="1" key={1}>
    //       Floor Plan
    //     </NavLink>
    //     <NavLink to={`/${showAbbr}/exhibitors`} eventKey="2" key={2}>
    //       Exhibitors
    //     </NavLink>
    //   </>
    // ),

    // (isAttendeeRegistrationEnabled) && (
    //   <NavLink to={`/${showAbbr}/attendees`} eventKey="14" key={14}>Attendees</NavLink>
    // ),
    // (isConferenceEnabled) && (
    //   <>
    //     <NavLink to={`/${showAbbr}/sponsors`} eventKey="9" key={9}>Sponsors</NavLink> 
    //     <NavLink to={`/${showAbbr}/sessions`} eventKey="3" key={3}>Sessions</NavLink>
    //   </>
    // ),
    // (isAttendeeRegistrationEnabled) && (
    //   <NavLink to={`/${showAbbr}/registration`} eventKey="10" key={10}>Registration</NavLink>
    // ),

    // (validateApprovedExhibitorUser(user, showId)) && (
    //   <NavLink to={`${showAbbr}/invoice#Account`} eventKey="1" key={1}>My Account</NavLink >
    // ),
    // show option only if the show property has enableLanguageSelector prop set to true
    validateCurrentUser(user) && enableLanguageSelector && (
      <Nav onClick={handleMobileNavClose} key={11}>
        <Nav.Item onClick={handleAuthButtonClick} key={6}>
          <GoogleTranslate handleMobileNavClose={handleMobileNavClose}/>
        </Nav.Item>
      </Nav>
    )
  ];

  const navigationLinks = !showUnpublished ? sortedLinks : [];

  return (
    <Nav onClick={handleMobileNavClose} key={10}>
      {navigationLinks}
      <Nav.Item onClick={handleAuthButtonClick} key={6}>
        <AuthNavigation handleMobileNavClose={handleMobileNavClose}/>
      </Nav.Item>
    </Nav>
  );
}

export default NavLinks;