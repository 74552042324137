import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import getAxios from "common/axios";
const axios = getAxios();

const REACT_APP_SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

// `${REACT_APP_SERVER_BASE_URL}/floorplan/attendee/questions`,
// This will fetch list of questions and answers. questions will be a list of objects. Answers will also be a list of objects
export const fetchQuestions = createAsyncThunk(
  "attendeeQuestions/fetchQuestions",
  async ({showId, contactId}) => {
    const response = await axios.get(
      `${REACT_APP_SERVER_BASE_URL}/floorplan/attendee/questions/${showId}/${contactId}`,
      {withCredentials: true}
    );
    return response.data;
  }
);

// This will update the questions and answers. questions will be a list of objects. Answers will also be a list of objects.
export const updateAnswers = createAsyncThunk(
  "attendeeQuestions/updateAnswers",
  async ({orgId, showId, contactId, answerList}) => {
    const response = await axios.post(
      `${REACT_APP_SERVER_BASE_URL}/floorplan/attendee/questions`,
      {answerList, showId, contactId, orgId},
      {withCredentials: true}
    );
    return response.data;
  }
);

const attendeeQuestionsSlice = createSlice({
  name: "attendeeQuestions",
  initialState: {
    questions: [],
    answers: [],
    ocrId: null,
    status: "idle",
    error: null,
    answersUpdateStatus: "idle",
    answersUpdateError: null,
  },
  reducers: {
    addAnswer: (state, action) => {
      const questionId = action.payload.questionId;
      const newAnswerText = action.payload.answerText;

      // Find the index of the answer object with the same questionId
      const existingAnswerIndex = state.answers.findIndex(answer => answer.questionId === questionId);

      if (existingAnswerIndex !== -1) {
        // Update the existing answer in an immutable way
        state.answers = state.answers.map((answer, index) =>
          index === existingAnswerIndex ? {...answer, answerText: newAnswerText} : answer
        );
      } else {
        // Add the new answer
        state.answers = [...state.answers, action.payload];
      }
    },
    removeAnswersFromState: (state, action) => {
      state.answers = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchQuestions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.questions = action.payload.questions;
        state.answers = action.payload.answers;
        state.ocrId = action.payload.ocrId;
      })
      .addCase(fetchQuestions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateAnswers.pending, (state) => {
        state.answersUpdateStatus = "loading";
      })
      .addCase(updateAnswers.fulfilled, (state, action) => {
        state.answersUpdateStatus = "succeeded";
        // state.questions = action.payload.questions;
        state.answers = action.payload.answers;
      })
      .addCase(updateAnswers.rejected, (state, action) => {
        state.answersUpdateStatus = "failed";
        state.answersUpdateError = action.error.message;
      });
  },
});

export const {addAnswer, removeAnswersFromState} = attendeeQuestionsSlice.actions;

export default attendeeQuestionsSlice.reducer;