import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import getAxios from 'common/axios';
const axios = getAxios();

const REACT_APP_SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

// fetch contact by ID
export const fetchContact = createAsyncThunk(
  'contact/fetchContact',
  async ({ contactId, accountId, showId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${REACT_APP_SERVER_BASE_URL}/floorplan/contacts/${contactId}`, {
        withCredentials: true,
        params: {
          showId,
          accountId
        }
      });
      return data.contact;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const contactSlice = createSlice({
  name: 'contact',
  initialState: {
    contact: {},
    status: "idle",
    error: null
  },
  reducers: {
    updateContact: (state, action) => {
      state.contact = {
        ...state.contact,
        ...action.payload,
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContact.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchContact.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.contact = action.payload;
      })
      .addCase(fetchContact.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  }
});

export const { updateContact } = contactSlice.actions;

export default contactSlice.reducer;
