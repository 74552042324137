import { useEffect } from 'react';
import {connect} from 'react-redux';
import { useLocation } from "react-router-dom";
import {deleteCookie} from 'components/utils';

const {
  REACT_APP_SERVER_BASE_URL
} = process.env;

let returnToUrl = null;

const Logout = (props) => {
  const location = useLocation();
  returnToUrl = encodeURI(location.state?.from.pathname || location?.pathname.replace("/logout", ''));
  
  useEffect(() => {
    props.logout();
  }, [])

  return null;
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: async () => {
      deleteCookie('user');
      await window.open(
        `${REACT_APP_SERVER_BASE_URL}/auth/logout/?returnTo=${returnToUrl}`,
        "_self"
      );
      // dispatch({ type: GET_CURRENT_USER, payload: null });
    }
  };
};

export default connect(null, mapDispatchToProps)(Logout);