import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCTS_OF_ACCOUNT
} from "store/actions/actionTypes";

const initialState = {
  products: [],
  boughtProducts: {
    sponsorships: [],
    confAttendees: [],
    isLoading: false,
    dataFetched: false,
  },
  isLoading: false,
  dataFetched: false,
  error: null
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        boughtProducts: {
          ...state.boughtProducts,
          isLoading: true,
          dataFetched: false
        },
        isLoading: true,
        dataFetched: false
      };
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        products: {allProducts: action.payload},
        dataFetched: true
      };
    case FETCH_PRODUCTS_OF_ACCOUNT:
      return {
        ...state,
        boughtProducts: {
          ...state.boughtProducts,
          isLoading: false,
          sponsorships: action.sponsorships || [],
          confAttendees: action.confAttendees || [],
          dataFetched: true
        }
      };
    case FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
export default productsReducer;