import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import getAxios from "common/axios";
const axios = getAxios();

const REACT_APP_SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

// Retrive list of sponsors for the show
export const fetchSponsors = createAsyncThunk(
  "sponsors/fetchSponsors",
  async (showId) => {
    const response = await axios.get(
      `${REACT_APP_SERVER_BASE_URL}/floorplan/sponsors/${showId}`,
      {withCredentials: true}
    );
    return response.data?.sponsors;
  }
);

const sponsorsSlice = createSlice({
  name: "sponsors",
  initialState: {
    sponsors: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSponsors.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSponsors.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.sponsors = action.payload;
      })
      .addCase(fetchSponsors.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});


export default sponsorsSlice.reducer;