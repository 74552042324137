const {FETCH_SELECTED_THEME} = require("store/actions/actionTypes");
const {REACT_APP_DEFAULT_THEME} = process.env;

const themeSwitchReducer = (state = {}, action) => {
  if(action.type === FETCH_SELECTED_THEME){
    return {
      ...state,
      selectedTheme: action.payload || REACT_APP_DEFAULT_THEME
    }
  }
  return state;
}

export default themeSwitchReducer;