import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
// import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import { createRoot } from 'react-dom/client';

// import App from 'components/App';
import reducers from 'store/reducers';
import {getValueFromString} from 'components/utils';
import App from 'components/App';

const {
  REACT_APP_DD_APP_ID,
  REACT_APP_DD_CLIENT_TOKEN,
  REACT_APP_DD_SITE,
  REACT_APP_DD_SERVICE,
  REACT_APP_DD_ENV,
  REACT_APP_ENABLE_DD_RUM} = process.env;

if(getValueFromString(REACT_APP_ENABLE_DD_RUM)){
  datadogRum.init({
    applicationId: REACT_APP_DD_APP_ID,
    clientToken: REACT_APP_DD_CLIENT_TOKEN,
    site: REACT_APP_DD_SITE,
    service: REACT_APP_DD_SERVICE,
    env: REACT_APP_DD_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog 
    // version: '1.0.0',
    sampleRate: 100,
    sessionReplaySampleRate: 20,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel:'mask-user-input'
  });
}
  

/* 
  ! Enables redux devtools 
  Redux devtools, enabled for development environment
*/
let store = createStore(reducers, applyMiddleware(reduxThunk));

if(process.env.REACT_APP_NODE_ENV !== 'production'){
  const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)|| compose;
  store = createStore(reducers, composeEnhancers(applyMiddleware(reduxThunk)));
}

let root = createRoot(document.querySelector('#root'));
root.render(
  <Provider store={store} >
    {/* <App /> */}
    <App />
  </Provider>
)