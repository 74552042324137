import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAxios from "common/axios";
const axios = getAxios();

const REACT_APP_SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

export const fetchAttendees = createAsyncThunk(
  "attendees/fetchAttendees",
  async ({ showId, page, limit, sortBy }) => {
    const response = await axios.get(
      `${REACT_APP_SERVER_BASE_URL}/floorplan/attendees/${showId}`,
      {
        params: { page, limit, sortBy },
        withCredentials: true
      }
    );
    return { attendees: response.data.attendees, totalAttendees: response.data.totalAttendees };
  }
);

const attendeesSlice = createSlice({
  name: "attendees",
  initialState: {
    attendees: {},
    status: "idle",
    error: null,
    page: 1,
    limit: 50,
    totalAttendees: 0
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAttendees.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAttendees.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.attendees[state.page] = action.payload.attendees;
        state.totalAttendees = action.payload.totalAttendees;
      })
      .addCase(fetchAttendees.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  }
});

export const { setPage, setLimit } = attendeesSlice.actions;

export default attendeesSlice.reducer;
