import {exhibitorUserAccountStatus, getAccountApprovalStatus} from "components/utils";
import {useSelector} from "react-redux";
import {ReactComponent as Verified} from "assets/images/check-circle.svg";
import {ReactComponent as Pending} from "assets/images/hourglass_bottom_24dp_FILL1_wght400_GRAD0_opsz24.svg";
import {ReactComponent as NotApproved} from "assets/images/cancel-fill.svg";

/**
 * statusIconClass: approved || pending || notApproved
 */
const AccountApprovalStatus = ({
  statusMessage = '',
  statusIconClass = '',
  statusTitle = '',
  showLabel = true
}) => {
  const showId = useSelector(state => state.show?._id);
  const user = useSelector(state => state.currentUser);
  const approvalStatusMessage = getAccountApprovalStatus(user, showId);

  let statusIcon = '';
  let statusClass = '';
  if ((!statusIconClass && (approvalStatusMessage === exhibitorUserAccountStatus.Approved)) || statusIconClass === "approved") {
    statusIcon = <Verified className="verifiedIcon" />;
    statusClass = 'approved';
  }
  else if ((!statusIconClass && (approvalStatusMessage === exhibitorUserAccountStatus.Pending)) || statusIconClass === "pending") {
    statusIcon = <Pending className="pendingIcon" />;
    statusClass = 'pending';
  }
  else if ((!statusIconClass && (approvalStatusMessage === exhibitorUserAccountStatus.NotApproved)) || statusIconClass === "notApproved") {
    statusIcon = <NotApproved className="notApprovedIcon" />;
    statusClass = 'notApproved';
  }
  else {
    statusIcon = '';
  }

  // if (!approvalStatusMessage) return null;

  return (
    <div className="statusContainer">
      <div className="statusMessage" title={statusTitle}>
        {showLabel && <span>Status:</span>}
        <span className={`statusText ${statusClass}`}>
          {statusMessage || approvalStatusMessage}
          <span className="statusIcon">{statusIcon}</span>
        </span>
      </div>
    </div>
  );
}

export default AccountApprovalStatus;