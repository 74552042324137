import React, { useState } from "react";
import Select from "react-select";
import "styles/forms.scss";

const SearchableSelectInput = (props) => {
  const {
    label,
    errorMessage,
    onChange,
    inputValue,
    onInputChange,
    id,
    name,
    className = '',
    showError = null,
    value,
    searchable = false,
    defaulttext = 'Select...',
    disabled = false,
    required = false,
    isLoading = false,
    addItemBtnText = "Add New",
    onAddItem = null,
    ...inputProps
  } = props;

  const selectoptions = inputProps?.selectoptions?.length
    ? inputProps.selectoptions
    : (inputProps.options || []);


  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      borderColor: inputError ? "#f00" : "#ccc", // Set border color based on inputError
      borderRadius: "4px",
    }),
  };

  const [inputError, setInputError] = useState(false);

  // use the selectedInputValue for getting the value.
  const handleInputChange = (selectedOption) => {
    // handle add item button click
    if (selectedOption?.label === addItemBtnText) {
      onAddItem();
      return;
    }

    const inputLabel = selectedOption ? selectedOption.label : "";
    const inputValue = selectedOption?.value || null;
    setInputError(inputLabel.length === 0 || !selectoptions.some(option => option.label.toLowerCase() === inputLabel.toLowerCase()));
    onChange({
      target: {
        name: name,
        value: inputLabel,
        selectedInputValue: inputValue
      }
    }); // use the selectedInputValue for getting the value.
  };

  // This is triggered when text input changes
  const handleOnInputChange = (value) => {
    onInputChange && onInputChange({
      target: {name, value}
    })
  }

  let options = selectoptions.map(option => ({
    label: option.label,
    value: option.value,
    subLabel: option.subLabel,
    subLabelHelpText: option.subLabelHelpText || '',
    isDisabled: option.isDisabled || false
  }));
  /* if the addItemBtnText is provided, then add the button to the options list.
  Clicking on the button will call the onAddItem function.
  Add the button as the first option in the list.
  The button can also be distinguished from the select options by styles.
  */
  if (onAddItem && addItemBtnText) {
    options = [{
      label: addItemBtnText,
      value: null
    }, ...options];
  }

  // format the option labels if the formattedOptionLabels function is not provided.
  const formatOptionLabel = (data) => {
    if (data.label === addItemBtnText) {
      return (
        <div className="addNewItem">
          <span>{data.label}</span>
        </div>
      );
    }
    return (
      <div className="selectOption">
        <span>{data.label || ''}</span>
        {data.subLabel && <span className="subLabel" title={data.subLabelHelpText || ''}>{data.subLabel || ''}</span>}
      </div>
    );
  };

  return (
    <div className={`formField pos-relative ${className ? className : ""}`}>
      {label && (
        <label>
          {required && <span className="asterisk">*</span>}
          {label}
        </label>
      )}
      <Select
        id={id}
        name={name}
        options={options || []}
        classNamePrefix="react-select"
        value={options.find(
          option => 
          option.value?.toLowerCase() === value?.toLowerCase()  // match the selected values
          || option.label?.toLowerCase() === value?.toLowerCase())  // match the value with label (this case is needed in the country select)
          || defaulttext
        }
        onChange={handleInputChange}
        inputValue={inputValue}
        onInputChange={handleOnInputChange}
        onBlur={() => setInputError(false)}
        styles={customStyles}
        isSearchable={searchable}
        isDisabled={disabled}
        className={className}
        placeholder={defaulttext}
        required={required}
        isLoading={isLoading}
        isOptionDisabled={(option) => option.isDisabled} // Disable options based on the isDisabled property
        formatOptionLabel={formatOptionLabel}
        // menuIsOpen={true}
      />
      {inputError && (
        <span className={`formField-errorMessage ${showError && "d-block"}`}>
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default SearchableSelectInput;
