import {FETCH_AUTOCOMPLETE_VALUES_FAILURE, FETCH_AUTOCOMPLETE_VALUES_REQUEST, FETCH_AUTOCOMPLETE_VALUES_SUCCESS} from "store/actions/actionTypes";

const initialState = {
  hashtags: [],
  isFetching: false,
  error: null
};

const hashtagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AUTOCOMPLETE_VALUES_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_AUTOCOMPLETE_VALUES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hashtags: action.payload
      };
    case FETCH_AUTOCOMPLETE_VALUES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default hashtagsReducer;