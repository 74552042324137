const {FETCH_ACCOUNT_CONTACTS, FETCH_ACCOUNT_CONTACTS_REQUEST} = require("store/actions/actionTypes")

const initialState = {
  contacts: [],
  booths: [],
  dataFetched: false,
}

const contactsReducer = (state = initialState, action) => {
  if(action.type === FETCH_ACCOUNT_CONTACTS_REQUEST){
    return {
      ...state,
      dataFetched: false
    }
  }
  else if(action.type === FETCH_ACCOUNT_CONTACTS){
    return {
      ...state,
      contacts: action.contacts || state.contacts,
      booths: action.booths || state.booths,
      error: action.error || null,
      dataFetched: true
    }
  }
  return state;
}

export default contactsReducer;