// import GoogleTranslate from 'components/GoogleTranslate';
import Loader from 'components/Loader';
import { lazy, Suspense } from 'react';

import React, {useState} from 'react'
import {Link} from 'react-router-dom';

const ThemeSwitcher = lazy(() => import('components/ThemeSwitcher'));
// const showcycleImage = '/images/ShowCycle_Logo.png' ;
const showcycleImage = '/images/ShowCycle_logo-1.svg';

const Footer = () => {
  const [showThemeSwitcher, setShowThemeSwitcher] = useState(false);
  
  return (
    <div className='footerWrapper'>
      <div className='container-fluid footer'>
        <div>
          <a href='https://showcycle.com/' target={"_blank"} rel="noreferrer" className='showCycleExternalLink'>
            <span className='poweredBy'>Powered by</span>
            {/* <img src={showcycleImage} alt="showcycle logo" style={styles.showcycleImage}/> */}

            {/* Get from public directory */}
            {
              showcycleImage && (
                <img 
                  src={showcycleImage} 
                  alt="showcycle logo" 
                  className='showcycleFooterImage' 
                  loading='lazy'
                  onError={(event) => event.target.style.display = 'none'}
                />
              )
            }
          </a>
        </div>
        <div className='footerLink-wrapper'>
          <button
            className="termly-cookie-preference-button footerLink footer-button"
            type="button" 
            onClick={() => window.displayPreferenceModal()}
          >
            Manage Cookie Preferences
          </button>
          <a 
            className='footerLink'
            target={'_blank'}
            referrerPolicy="no-referrer"
            href='https://app.termly.io/notify/58f194c9-b2bb-465f-94b2-5f5be9d6aead'
          >
            Do not sell my info 
          </a>
          <Link
            to={`/privacy-policy`}
            target='_blank'
            referrerPolicy='no-referrer'
            className="footerLink"
          >
            Privacy Policy
          </Link>
          <button
            className='footerLink no-border'
            onClick={() => setShowThemeSwitcher(!showThemeSwitcher)}
          >
            Change Theme  
          </button>
          {showThemeSwitcher && (
            <Suspense >
              <ThemeSwitcher showThemeSwitcher={showThemeSwitcher} setShowThemeSwitcher={setShowThemeSwitcher}/>
            </Suspense>
          )}
        </div>
      </div>
    </div>
  )
}

export default Footer;