import React from 'react';

const Loader = ({spinColor = '#2497cf', message=null}) => {
  return (
    <div className='loader-container'>
      <div className="loader-spinner">
        <div style={{borderColor: spinColor}}></div>
        <div  style={{borderColor: spinColor}}></div>
      </div>
      {message && (
        <div className='loader-message'>{message}</div>
      )}
    </div>
  )
}

export default Loader;