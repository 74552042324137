import {SET_500} from "store/actions/actionTypes";

const internalServerErrorReducer = (state = {}, action) => {
  if (action.type === SET_500){
    let internalServerError = false;
    if(action.payload !== undefined){
      internalServerError = {status: action.payload};
    }
    return {...state, ...internalServerError};
  }
  return state;
}

export default internalServerErrorReducer;