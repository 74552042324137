import { useRef, useEffect } from 'react'

/**
 * @param {string} title Title of the page.
 * @param {boolean} prevailOnUnmount maintain the same title if the component using this hook is unmounted. If you don't want to use it, the default value is false
 */

function useDocumentTitle(title = null, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    if(title){
      document.title = `${title} - ${document.title}`;
    }
  }, [title]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [])
}

export default useDocumentTitle