import FormField from 'components/forms/FormInput';
import React, {useState, lazy, Suspense, useEffect} from "react";
import {Spinner} from 'react-bootstrap';
import {PencilFill} from 'react-bootstrap-icons';
import {useSelector, useDispatch} from 'react-redux';
import {ReactComponent as UserAvatar} from 'assets/images/user-avatar.svg';
// import {fetchCurrentUser} from 'store/actions/index';
import {Alert} from 'react-bootstrap';

import "styles/profile.scss";
import getAxios from 'common/axios';
import {UPDATE_USER_PROFILE} from 'store/actions/actionTypes';

// import AttendeeQuestions from 'components/exhibitorPortal/Attendees/AttendeeQuestions/index'; 
const AttendeeQuestions = lazy(() => import("../exhibitorPortal/Attendees/ConfAttendees/AttendeeQuestions/index"));

const axios = getAxios();
const {REACT_APP_SERVER_BASE_URL} = process.env;

const Profile = () => {
  const user = useSelector((state) => state.currentUser || {});
  const isAttendeeRegistrationEnabled = useSelector(state => state.show?.isAttendeeRegistrationEnabled || false);
  const isRegisteredAttendee = useSelector(state => state.currentUser?.isRegisteredAttendee || false);
  const isAttendeeQuestionsEnabled = isAttendeeRegistrationEnabled && isRegisteredAttendee;

  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    photoLocalURL: (user.photoLocalURL || user.photo) || ''
  })
  const [editableFormInput, setEditableFormInput] = useState(false);
  const [processing, setProcessing] = useState(false); // for showing loader button while submitting company info.
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  useEffect(() => {
    setFormValues({
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      photoLocalURL: (user.photoLocalURL || user.photo) || ''
    });
  }, [user]);

  const handleFormEditToggle = (e) => {
    setEditableFormInput(true);
  };
  const handleFormEditToggleCancel = (e) => {
    setEditableFormInput(false);
  };


  const onChange = (e) => {
    // merge the form values in the state on any form input change
    setFormValues({...formValues, [e.target.name]: e.target.value});
  };


  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      let updatedUserData = {
        ...formValues
      }

      const res = await axios.post(
        `${REACT_APP_SERVER_BASE_URL}/floorplan/update/user`,
        {data: JSON.stringify(updatedUserData)},
        {withCredentials: true}
      );


      const {data} = res;
      if (data.userEmail) {
        dispatch({
          type: UPDATE_USER_PROFILE,
          payload: formValues,
        });

        setShowErrorAlert(false);
        setShowSuccessAlert(true);
        setEditableFormInput(false);
      } else {
        throw "formError";
      }
    } catch (e) {
      setShowSuccessAlert(false);
      setShowErrorAlert(true);
      setEditableFormInput(false);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className="profileWrapper wrapperContainer">
      <h2 className='heading-primary'>User Profile</h2>
      <div className="profile-info sidebarItemWrap">
        {showSuccessAlert && (
          <Alert
            variant="success"
            dismissible
            onClose={() => setShowSuccessAlert(false)}
          >
            User details updated successfully.
          </Alert>
        )}
        {showErrorAlert && (
          <Alert
            variant="danger"
            dismissible
            onClose={() => setShowErrorAlert(false)}
          >
            <p>Your form has not been submitted.</p>
            <p>
              There was an error while updating the user profile.
              Please try again after some time.
            </p>
          </Alert>
        )}
        <form onSubmit={handleFormSubmit}>
          <div className='profile-topSection'>
            <div>
              <div className="profile-avatar">
                {
                  formValues.photoLocalURL ?
                    <img
                      className='profileImage'
                      src={formValues.photoLocalURL}
                      alt='user avatar'
                      onError={(event) => event.target.style.display = 'none'}
                    />
                    : <UserAvatar className='profileImage' />
                }
              </div>
              <div className="profileName heading-secondary">{user.name}</div>
              <div className='profileEmail'>{user.email}</div>
            </div>
            <div>
              <div className="invoice-formEdit" onClick={handleFormEditToggle}>
                {!editableFormInput ? (
                  <div className="edit-btn button-primary">
                    <PencilFill className="invoice-formEdit-icon" />
                    <span className="edit-text">Edit</span>
                  </div>
                ) : !processing ? (
                  <button className="button-primary btn" type='submit'>
                    Save
                  </button>
                ) : (
                  <button className="button-primary btn">
                    <Spinner
                      animation="border"
                      role="status"
                      className="spinner-component"
                    />
                  </button>
                )}
              </div>

              {editableFormInput ? (
                <button
                  className="button-primary cancel-button btn"
                  onClick={handleFormEditToggleCancel}
                >
                  Cancel
                </button>
              ) : null}
            </div>
          </div>
          {/* User Information Display */}
          <div className='userProfileForm'>
            <div className='leftSection'>
              <div className="form-group">
                <FormField
                  type="text"
                  name="firstName"
                  value={formValues.firstName}
                  onChange={onChange}
                  label="First Name"
                  disabled={!editableFormInput}
                  required={true}
                />
              </div>
              <div className="form-group">
                <FormField
                  type="text"
                  name="lastName"
                  value={formValues.lastName}
                  onChange={onChange}
                  label="Last Name"
                  disabled={!editableFormInput}
                  required={true}
                />
              </div>
            </div>

            <div className='rightSection'>
              {/* Email (non-editable) */}
              <div className="form-group">
                <FormField disabled={true} type="email" id="email" value={user.email || ''} readOnly label="email" />
              </div>

              {/* Social Login Provider (non-editable) */}
              <div className="form-group">
                <FormField
                  disabled={true}
                  type="text"
                  id="social-login"
                  value={user.provider || ''}
                  readOnly
                  label="Login Provider"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      {/* Conditional Rendering of AttendeeQuestions */}
      {isAttendeeQuestionsEnabled && (
        <Suspense fallback={<div>Loading...</div>}>
          <AttendeeQuestions />
        </Suspense>
      )}
    </div>
  );
};

export default Profile;
