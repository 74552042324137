import {
  FETCH_EXHIBITORS_FAILURE,
  FETCH_EXHIBITORS_REQUEST,
  FETCH_EXHIBITORS_SUCCESS
} from "store/actions/actionTypes";

const initialState = {
  exhibitors: null,
  shows: null,
  isFetching: false
}

const exhibitorsReducer = (state = initialState, action) => {
  switch(action.type){
    case FETCH_EXHIBITORS_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_EXHIBITORS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        exhibitors: action.exhibitors,
        shows: action.shows
      };
    case FETCH_EXHIBITORS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload || "",
        exhibitors: [],
        shows: []
      }
    default:
      return state;
  }
}

export default exhibitorsReducer;