import React, { useEffect, useRef, useState } from "react";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

const PhoneNumInput = ({ label,
  errorMessage,
  onChange,
  id,
  type,
  className = null,
  showError = null,
  value,
  name="phone",
  required=false,
  ...inputProps }) => {
    
  const [phone, setPhone] = useState(value);
  const [showErrMsg, setShowErrMsg] = useState(false);
  const phoneInputRef = useRef(null);

  useEffect(() => {
    if(value){
      setPhone(value);
    }
    // reset the state when the component unmounts.
    return () => {
      setPhone("");
      setShowErrMsg(false);
    }
  }, [value])


  // observe the phone input, show the error message if input has class 'hasError'
  useEffect(() => {
    const phoneInput = phoneInputRef.current;

    const observer = new MutationObserver(() => {
      if (phoneInput.classList.contains('hasError')) {
        setShowErrMsg(true);
      }
    });

    observer.observe(phoneInput, { attributes: true });

    // set custom validity for phone input. the input will be invalid if the phone length is less than 8
    if(inputProps?.required){
      if (phoneInput.value.length < 8) {
        phoneInput.setCustomValidity('Phone number must have a minimum of 8 characters');
      } else {
        phoneInput.setCustomValidity('');
      }
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const onInputChange = (value, e) => {
    const phoneInput = phoneInputRef.current;
    onChange({ target: { name: name, value: value } });
    if (!value || value.length < 8 || value.match(/12345/) || value.match(/1234/)) {
      inputProps?.required && phoneInput.setCustomValidity('Phone number must have a minimum of 8 characters');
      setShowErrMsg(true);
      return false;
    }
    else {
      inputProps?.required && phoneInput.setCustomValidity('')
      setShowErrMsg(false)
      return true;
    }
  }

  return (
    <div className="formField">
      {label && (
        <label>
          {required && <span className="asterisk">*</span>}
          {label}
        </label>
      )}
      <PhoneInput
        country={'us'}
        value={phone}
        onChange={(value, data, e) => {
          setPhone(value);
          onInputChange(value, e);
        }}
        // onInputChange={checkCustomValidity}
        enableSearch={true}
        disableSearchIcon={true}
        disabled={inputProps.disabled || false}
        // defaultErrorMessage={errorMessage}
        isValid={inputProps?.required ? (!showErrMsg && phone?.length > 8) : !showErrMsg}
        inputProps={{
          name: name,
          required: inputProps?.required || false,
          type: "tel",
          autoComplete: "tel",
          placeholder: inputProps.placeholder || "Phone Number",
          id: "reactPhoneInput",
          ref: phoneInputRef
        }}
      />
      <span 
        className={`formField-errorMessage ${showErrMsg && 'd-block'}`}
      >
        {errorMessage}
      </span>
    </div>
  );
};

export default PhoneNumInput;
