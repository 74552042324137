import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import getAxios from 'common/axios';
const axios = getAxios();

const REACT_APP_SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

// Cache for storing search results
const searchCache = new Map();
let currentAbortController = null;

export const searchAccounts = createAsyncThunk(
  'accountSearch/searchAccounts',
  async ({ keyword, showId }, { rejectWithValue, getState }) => {
    if (!keyword) {
      return getState().accountSearch.allAccounts;
    }

    // Check if the result is already cached
    if (searchCache.has(keyword)) {
      return searchCache.get(keyword);
    }

    // Abort the previous request if a new one is made
    if (currentAbortController) {
      currentAbortController.abort();
    }

    // Create a new AbortController for the current request
    currentAbortController = new AbortController();

    try {
      const { data } = await axios.get(`${REACT_APP_SERVER_BASE_URL}/floorplan/search/accounts`, {
        withCredentials: true,
        params: {
          keyword,
          showId,
        },
        signal: currentAbortController.signal,
      });

      // Store the result in the cache
      searchCache.set(keyword, data.accounts);

      // Update the complete list of fetched records
      const allAccounts = [...new Set([...getState().accountSearch.allAccounts, ...data.accounts])];

      return { accounts: data.accounts, allAccounts };
    } catch (err) {
      if (err.name === 'AbortError') {
        // Request was aborted
        return rejectWithValue({ message: 'Request aborted' });
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const accountSearchSlice = createSlice({
  name: 'accountSearch',
  initialState: {
    accounts: [],
    allAccounts: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    updateAccounts: (state, action) => {
      state.accounts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchAccounts.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(searchAccounts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.accounts = action.payload.accounts || action.payload;
        state.allAccounts = action.payload.allAccounts || state.allAccounts;
      })
      .addCase(searchAccounts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { updateAccounts } = accountSearchSlice.actions;
export default accountSearchSlice.reducer;
