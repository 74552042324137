import React, { useState } from 'react';
import Select from "react-select";
import "styles/forms.scss";
import PhoneNumInput from "components/forms/PhoneNumInput";
import SearchableSelectInput from "./SearchableSelectInput";

// helper function for multi-select list.
const formatGroupLabel = (data) => {
  return (
    <div className="multiSelect-groupStyle selectOption">
      <span className="categoryLabel optionLabel">{data.label}</span>
      <span className="multiSelect-BadgeStyle">{data.options.length}</span>
    </div>
  );
};

// helper function for multi-select list.
const formatOptionLabel = (data) => {
  return (
    <div className="selectSubOption">
      <span className="subCategoryLabel subOptionLabel">{data.label}</span>
    </div>
  );
};

// if an input has pattern then mention add an attribute "data-pattern-validate": true to prevent getting an input that doen't match the pattern
const FormField = (props) => {
  const [focused, setFocused] = useState(false);
  const handleInputFocus = (e) => {
    setFocused(true);
  };

  // Function to check if a proposed input matches a given pattern
  const inputMatchesPattern = function (e) {
    // Destructuring properties from the event target
    // pattern should be enclosed in quotes, regular expression syntax isn't working.
    const { value, selectionStart, selectionEnd, pattern } = e.target;

    // Getting the pressed character
    const character = e.key;

    // Constructing the proposed entry by replacing the selected portion with the pressed character
    const proposedEntry =
      value.slice(0, selectionStart) + character + value.slice(selectionEnd);

    // Declaring a variable to store the regular expression object
    let patternRegex;

    try {
      // Attempting to create a regular expression from the pattern string
      patternRegex = new RegExp(pattern);
    } catch (error) {
      // Handling invalid regular expression pattern
      console.error("Invalid regular expression pattern:", error);
      return false;
    }

    // Testing if the proposed entry matches the pattern
    const match = patternRegex.test(proposedEntry);

    // Returning true if the key event should be allowed, otherwise false
    return (
      e.metaKey || // Allow cmd/ctrl key
      e.which <= 0 || // Allow arrow keys
      e.which === 8 || // Allow delete key
      (match && match[0] === match.input) // Check if the proposed entry matches the pattern
    );
  };

  const handleOnChange = (e) => {
    e.target.classList.remove("hasError"); //remove the error className when input changes.
    // check if event is present and the input has a 'data-pattern-validate' attribute.
    // if pattern of the input element doen't match the input entered then the value will not be set in the input element.
    if (
      e &&
      e.target.attributes["data-pattern-validate"] &&
      !inputMatchesPattern(e)
    ) {
      return e.preventDefault();
    }
    return onChange;
  };

  const handleOnInput = (e) => {
    if (e.target.attributes["data-validate-float-only"]) {
      e.target.value = e.target.value
        .replace(/[^0-9.]/g, "") // Remove any non-numeric or non-decimal point characters
        .replace(/(\..*)\./g, "$1") // Remove any additional decimal points
        .replace(/^(\d*\.\d{0,2}).*$/g, "$1"); // Limit the input to two decimal places
    }
  };

  const searchableSelectProps = { ...props };
  const {
    label = null,
    errorMessage = null,
    onChange,
    id,
    type,
    className = null,
    showError = null,
    searchable = false,
    isMulti = false,
    ...inputProps
  } = props;

  if (type === "select") {
    const selectoptions =
      Object.fromEntries(
        Object.entries(inputProps?.selectoptions || {})?.sort()
      ) || {};

    if (searchable) {
      return <SearchableSelectInput {...searchableSelectProps} />;
    } else if (isMulti) {
      const options = inputProps?.selectoptions || inputProps?.options;
      // sort the options by label
      const selectoptions = options
        ?.slice()
        .sort((a, b) => a?.label?.localeCompare(b?.label));
      return (
        <div className={`formField ${className ? className : ""}`}>
          {label && (
            <label>
              {inputProps.required && <span className="asterisk">*</span>}
              {label}
            </label>
          )}
          <Select
            isMulti
            onChange={(value) =>
              onChange({
                target: {
                  name: inputProps.name,
                  value: value,
                },
              })
            }
            className={className}
            classNamePrefix="react-select"
            options={selectoptions || []}
            value={inputProps.selectedValues}
            defaultValue={inputProps.defaultValue || []}
            formatGroupLabel={formatGroupLabel}
            formatOptionLabel={formatOptionLabel}
            placeholder={inputProps.placeholder}
          />
        </div>
      );
    }

    const { defaulttext = null, ...props } = inputProps;
    return (
      <div className={`formField ${className ? className : ""}`}>
        {label && (
          <label>
            {props.required && <span className="asterisk">*</span>}
            {label}
          </label>
        )}
        <select
          {...props}
          onChange={onChange}
          onBlur={handleInputFocus}
          focused={focused.toString()}
          value={props.value || ""}
        >
          {defaulttext && (
            <option value={""} disabled>
              {defaulttext}
            </option>
          )}
          {Object.keys(selectoptions).map((prop) => {
            return (
              <option className="select-option" value={prop} key={prop}>
                {selectoptions[prop]}
              </option>
            );
          })}
        </select>
        <span className={`formField-errorMessage ${showError && "d-block"}`}>
          {errorMessage}
        </span>
      </div>
    );
  }
  if (type === "textarea") {
    return (
      <div className={`formField ${className ? className : ""}`}>
        {label && (
          <label>
            {props.required && <span className="asterisk">*</span>}
            {label}
          </label>
        )}
        <textarea
          cols={props.cols}
          rows={props.rows}
          {...inputProps}
          onKeyDown={handleOnChange}
          onChange={onChange}
          onBlur={handleInputFocus}
          focused={focused.toString()}
          type={type}
        ></textarea>
        <span className={`formField-errorMessage ${showError && "d-block"}`}>
          {errorMessage}
        </span>
      </div>
    );
  }
  if (type === "tel") {
    return <PhoneNumInput {...props} />;
  }
  // for radio type input
  if (type?.toLowerCase() === "radio") {
    return (
      <div className={`formField ${className ? className : ""}`}>
        {label && (
          <label>
            {props.required && <span className="asterisk">*</span>}
            {label}
          </label>
        )}
        <div className="radioGroup">
          {props.options?.map((option, index) => {
            return (
              <div 
                key={index} 
                className={`radio ${props.value === option ? ' selected' : ''} ${inputProps.disabled ? ' disabled' : ''}`}
                onClick= {() => onChange({target: {name: props.name, value: option}})}
              >
                <label htmlFor={option}>{option}</label>
                <input
                  type="radio"
                  id={option}
                  name={props.name}
                  value={option}
                  checked={props.value === option}
                  // onChange={onChange}
                  // readOnly={true}
                  disabled={inputProps.disabled || false}
                  required={props.required || false}
                />
              </div>
            );
          })}
        </div>
        <span className={`formField-errorMessage ${showError && "d-block"}`}>
          {errorMessage}
        </span>
      </div>
    );
  }

  return (
    <div className={`formField ${className ? className : ""}`}>
      {label && (
        <label>
          {props.required && <span className="asterisk">*</span>}
          {label}
        </label>
      )}
      <input
        {...inputProps}
        onKeyDown={handleOnChange}
        onChange={onChange}
        onBlur={handleInputFocus}
        focused={focused.toString()}
        type={type}
        onInput={handleOnInput}
        className={`${showError && "invalid"}`}
      />
      <span className={`formField-errorMessage ${showError && "d-block"}`}>
        {errorMessage}
      </span>
    </div>
  );
};
export default FormField;