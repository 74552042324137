import axios from "axios";

axios.defaults.headers = {
  'Chache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
}

const getAxios = () => {
  return axios;
}

export default getAxios;