import {GET_ALL_PAST_SHOWS} from "store/actions/actionTypes";

const allShowsPastReducer = (state = {}, action) => {
  if (action.type === GET_ALL_PAST_SHOWS){
    let shows = null;
    if(action.payload !== undefined){
      shows = {pastShows: action.payload, orgs: action.orgs};
    }
    return {...state, ...shows};
  }
  return state;
}

export default allShowsPastReducer;