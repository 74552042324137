import {findIana} from "windows-iana";
import { tz } from 'moment-timezone';

import { getUserFromCookie } from "common";

export const SHOW_RECORD_TYPES = {
  EXHIBITION: 'Exhibition',
  CONFERENCE: 'Conference'
};

export const exhibitorUserAccountStatus = {
  Approved: "Approved",
  Pending: "Waiting for Approval",
  NotApproved: "Not Approved",
  Banned: "Banned",
};

// validates a normal user, it will check for user id only.
export const validateCurrentUser = (user = {}) => {
  // if user and user is an object with 0 keys then use getUserFromCookie() to get user data.
  if (user && Object.keys(user).length === 0) {
    user = getUserFromCookie() || {};
  }

  /** currentUser's _id property is set to null if user's not logged in */
  if (
    !user ||
    user._id === null ||
    Object.keys(user).length === 0
  ) {
    return false;
  }
  return true;
};

// validates if the user has loggin in with an exhibitor account.
export const validateExhibitorUser = (user = {}) => {
  if (Object.keys(user).length === 0) {
    user = getUserFromCookie() || {};
  }

  if (validateCurrentUser(user) && user.isExhibitor) {
    return true;
  }
  return false;
};

// validates if the user is an approved exhibitor user.
/**
 *
 * @param {object} user User data
 * @param {string} showId show id to check if user is approved in that show
 * @returns {boolean} true--if user is approved in given show otherwise false.
 */
export const validateApprovedExhibitorUser = (user = {}, showId) => {
  if (!user || (user && Object.keys(user).length === 0)) {
    user = getUserFromCookie() || {};
  }

  if (
    validateCurrentUser(user) &&
    user?.isExhibitor &&
    user?.approvedInShows?.includes(showId)
  ) {
    return true;
  }
  return false;
};

export const validateNotApprovedExhibitorUser = (user = {}, showId) => {
  if (Object.keys(user).length === 0) {
    user = getUserFromCookie() || {};
  }

  if (
    validateCurrentUser(user) &&
    user.isExhibitor &&
    user.notApprovedInShows?.includes(showId)
  ) {
    return true;
  }
  return false;
};



export const hasSubmittedTheFormForShow = (user, showId) => {
  if (user?.formSubmittedForShows?.includes(showId) || validateApprovedExhibitorUser(user, showId)) {
    return true;
  }
  return false;
};

export const getAccountApprovalStatus = (user, showId) => {
  if (validateApprovedExhibitorUser(user, showId)) {
    return exhibitorUserAccountStatus.Approved;
  } else if (validateNotApprovedExhibitorUser(user, showId)) {
    return exhibitorUserAccountStatus.NotApproved;
  } else if (hasSubmittedTheFormForShow(user, showId)) {
    return exhibitorUserAccountStatus.Pending;
  }
  return null;
}

const SAFE_URL_PATTERN = /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi;

function _sanitizeUrl(url) {
  url = String(url);

  if (url === "null" || url.length === 0 || url === "about:blank")
    return "about:blank";

  if (url.match(SAFE_URL_PATTERN)) return url;

  return `unsafe:${url}`;
}

export function sanitizeUrl(url = "about:blank") {
  return _sanitizeUrl(String(url).trim());
}
// used by action creators (api calls to backend).
// return false if logged in user is exhibitor otherwise true.
export const fetchFromCache = () => {
  let userCookie = getUserFromCookie();
  if (!userCookie || !userCookie.isExhibitor) {
    return true;
  } else {
    return false;
  }
};

// Set a Cookie
/**
 * 
 * @param {string} cName cookie name
 * @param {string} cValue cookie value JSON
 * @param {number} expHours cookie expiry time in hours
 */
export const setCookie = (cName, cValue, expHours = 24) => {
  let date = new Date();
  date.setTime(date.getTime() + (expHours * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  const domain = `;domain=` + (process.env.REACT_APP_NODE_ENV === "local" ? "" : `.showcycle.com`);
  const cookie = cName + "=" + cValue + ";" + expires + domain + ";path=/";
  document.cookie = cookie;
}

export const deleteCookie = (name) => {
  const domain = `;domain=` + (process.env.REACT_APP_NODE_ENV === "local" ? "" : `.showcycle.com`);
  document.cookie = name +'=;expires=Thu, 01 Jan 1970 00:00:01 GMT' + domain + ';path=/;';
}

//to convert the date format in the sessions
export const convertdate = (inputdate) => {
  const d = new Date(inputdate);
  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (monthNames[d.getUTCMonth()] +" " +("0" + d.getUTCDate()).slice(-2) +", " +d.getUTCFullYear());
};

//to convert the date format in the exhibior home
export const convertExhibitordate = (inputdate) => {
  const d = new Date(inputdate);
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    monthNames[d.getUTCMonth()] + " " +("0" + d.getUTCDate()).slice(-2) +", " +d.getUTCFullYear());
};

//to convert the session start and end time format
export const formatstTime = (timeString) => {
  const [hourString, minute] = timeString.split(":");
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getValueFromString = (val) => {
  if (val === "true" || val === "True" || val === "TRUE") {
    return true;
  } else if (val === "false" || val === "False" || val === "FALSE") {
    return false;
  } else if (val === "null" || val === "Null" || val === "NULL") {
    return null;
  } else if (
    val === "undefined" ||
    val === "Undefined" ||
    val === "UNDEFINED"
  ) {
    return undefined;
  }
  return val;
};

export const substract = (list1=[], list2=[]) => {
  return list1.filter(item => !list2.includes(item))
}

export const base64ToBlob = (base64, mimeType) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
};


/**
 * Checks if daylight saving time (DST) is currently in effect for a given date and time zone.
 *
 * @param {Date} date - The date for which you want to check DST status.
 * @param {string} timeZoneIANA - The IANA/Olson timezone identifier (e.g., 'America/New_York').
 * @returns {boolean} - True if DST is currently in effect, false otherwise.
 */
export function isDaylightSavingTimeActive(date, timeZoneIANA) {
  try {
    // Create a Moment.js object with the provided date and time zone
    const momentObject = tz(date, timeZoneIANA);
  
    // Check if daylight saving time is currently in effect
    return momentObject.isDST();
  } catch (err) {
    return false;
  }
}

export const getAbbreviationFromWindowsTimezone = (timeZoneIANA, isoDateTime) => {
  if(timeZoneIANA) {
    const abbreviation = tz(isoDateTime, timeZoneIANA)?.format('z');
    return abbreviation;
  }
}

/**
 * Combines a date string and a time string to create a formatted ISO date-time string
 * without a timezone component.
 *
 * @param {string} dateStr - The date in the format 'YYYY-MM-DD'.
 * @param {string} timeStr - The time in the format 'HH:MM:SS.SSSZ', where 'Z' represents the timezone offset.
 * @returns {string} A formatted ISO date-time string without a timezone component in the format 'YYYYMMDDTHHMMSS'.
 *
 */
export function getISOWithoutTimezone(dateStr, timeStr) {
  try {   
    const date = new Date(dateStr + 'T' + timeStr);
    const isoString = date?.toISOString()?.replace(/[-:]/g, '')?.slice(0, 15); // replace "-" from date and remove timezone from time ".000Z"
    return isoString;
  } catch (err) {
    return null
  }
}

export function getTimeZoneIANAFromWindowsFormat(windowsTimeZone) {
  let timeZoneIANA = null;
  // Regular expression pattern to match any timezone string
  // Regular expression pattern to match the timezone string up to 'Time'
  const pattern = /[-+]\d{2}:\d{2}\s+([^]*?Time)/;  // Use the RegExp.exec() method to find the match

  const match = pattern.exec(windowsTimeZone);

  if (match) {
    let timezoneString = match[1]?.trim(); // example: 'Eastern Standard Time'

    // replace the string daylight to standard to get the iana format
    timezoneString = timezoneString.replace('Daylight', 'Standard');
    timeZoneIANA = findIana(timezoneString)[0] || null; // example: 'America/New_york'

    return timeZoneIANA
  }
  return null;
}


export function getUTCOffset(timezoneIANA, datetimeString) {
  // Parse the datetime string into a moment.js object using the specified timezone
  const datetime = tz(datetimeString, timezoneIANA);

  // return the timezone offset in minutes, including daylight saving time if applicable
  return datetime?.utcOffset() || null;
};

export function getDatetimeInUTC(datetimeString, timezoneIANA) {
  // Parse the datetime string into a moment.js object using the specified timezone
  const datetime = tz(datetimeString, timezoneIANA);

  // return the timezone offset in minutes, including daylight saving time if applicable
  const offset = datetime?.utcOffset() || 0;

  // Subtract the offset to convert to UTC (UTC+00:00) datetime
  const datetimeUTC = datetime.subtract(offset, 'minutes');

  // format in this format: "YYYYMMDDTHHMMSS"
  const formattedDatetimeUTC = datetimeUTC.format('YYYYMMDDTHHmmss');
  return formattedDatetimeUTC;
}