import {GET_ALL_SHOWS} from "store/actions/actionTypes";

const allShowsReducer = (state = {}, action) => {
  if (action.type === GET_ALL_SHOWS){
    let shows = null;
    if(action.payload !== undefined){
      shows = {shows: action.payload, orgs: action.orgs};
    }
    return {...state, ...shows};
  }
  return state;
}

export default allShowsReducer;