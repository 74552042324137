import React, { lazy, Suspense, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import Loader from 'components/Loader';
import { setCookie } from 'components/utils';

const SearchLanding = lazy(() => import('components/landingPage/Search'));

const Wrapper = () => {
  const [params] = useSearchParams();
  const userFromParam = decodeURIComponent(params.get('user')) || null;
  const returnTo = JSON.parse(decodeURIComponent(params.get('returnTo'))) || null;

  useEffect(() => {
    if (userFromParam && userFromParam !== "null") {
      setCookie('user', userFromParam, 24);
    }
    if (returnTo && returnTo !== "null") {
      window.open(returnTo, '_self');
    }
  }, [userFromParam, returnTo]);

  return (
    <Suspense fallback={<Loader />}>
      <SearchLanding />
    </Suspense>
  );
}

export default Wrapper;
