import getAxios from "common/axios";
import {fetchFromCache} from "components/utils";
const {
  REACT_APP_SERVER_BASE_URL
} = process.env;

const axios = getAxios();

export const returnValidatedURL = (url) => {
  if (url === null || url === undefined) return '#';
  else if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
    return url
  }
  return `//${url}`
}

export const getShowAbbreviation = () => {
  return window.location.pathname.split('/')[1]?.toLocaleLowerCase();
}

export const getCurrentShowId = async () => {
  let showAbbr = getShowAbbreviation();
  let useCache = fetchFromCache();
  let showId = null;
  try{
    let {data} = await axios.get(`${REACT_APP_SERVER_BASE_URL}/show/${showAbbr}`, {
      params: {
        useCache
      }
    });
    showId = data.showId;
    return showId;
  }
  catch(err){ console.warn(err.message); return null };
}

export function getCookieByName(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) 
  return decodeURIComponent(parts.pop().split(';').shift());
}

export function getUserFromCookie() {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; user=`);
  if (parts.length === 2){
    let decodedCookie = decodeURIComponent(parts.pop().split(';').shift());
    let [j, ...user] = decodedCookie.split(":")
    user = JSON.parse(user.join(":"));
    return user;
  }
}

/**
 * 
 * @param {string} currency currency code. example: 'USD', 'EUR'
 * @returns `currency symbol` if found otherwise the same string will be returned.
 */
export const getSymbol = currency => {
  const symbol = new Intl.NumberFormat(
    'en', 
    { style: 'currency', currency })
    .formatToParts(currency)
    .find(x => x.type === 'currency'
  );
  return symbol && symbol.value; 
}

export const redirectWithRefresh = (url = getShowAbbreviation()) => {
  window.location.replace(url);
}