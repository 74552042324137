import {SET_404} from "store/actions/actionTypes";

const pageNotFoundReducer = (state = {}, action) => {
  if (action.type === SET_404){
    let pageNotFound = false;
    if(action.payload !== undefined){
      pageNotFound = {status: action.payload};
    }
    return {...state, ...pageNotFound};
  }
  return state;
}

export default pageNotFoundReducer;