import {getShowAbbreviation} from "common/index";
const showAbbr = getShowAbbreviation();

export const showNavConfig = {
  kcmafc24: {
    links: [
      { label: "Home", to: `/${showAbbr}`, order: 1 },
      { label: "Floor Plan", to: `/${showAbbr}/floorplan`, order: 2, condition: 'Floorplan' },
      { label: "Exhibitors", to: `/${showAbbr}/exhibitors`, order: 3, condition: 'Floorplan' },
      { label: "Speakers", link: "https://kcma.org/event-microsite/speakers", order: 4, external: true },
      { label: "Tours", link: "https://kcma.org/event-microsite/2024-fall-conference-tours", order: 5, external: true },
      { label: "Hotel", link: "https://kcma.org/event-microsite/hotel", order: 6, external: true },
      { label: "Agenda", to: `/${showAbbr}/sessions`, order: 7, condition: 'Conference' },
      { label: "Who's Coming", to: `/${showAbbr}/attendees`, order: 8, condition: 'AttendeeRegistration' },
      { label: "Sponsors", to: `/${showAbbr}/sponsors`, order: 9, condition: 'Conference' },
      { label: "Registration", to: `/${showAbbr}/registration`, order: 10, condition: 'AttendeeRegistration' },
      { label: "My Account", to: `${showAbbr}/invoice#Account`, order: 11, condition: 'isExhibitorUser' },
    ]
  },
  DEFAULT: {
    links: [
      { label: "Home", to: `/${showAbbr}`, order: 1 },
      { label: "Floor Plan", to: `/${showAbbr}/floorplan`, order: 2, condition: 'Floorplan' },
      { label: "Exhibitors", to: `/${showAbbr}/exhibitors`, order: 3, condition: 'Floorplan' },
      { label: "Attendees", to: `/${showAbbr}/attendees`, order: 4, condition: 'AttendeeRegistration' },
      { label: "Sponsors", to: `/${showAbbr}/sponsors`, order: 5, condition: 'Conference' },
      { label: "Sessions", to: `/${showAbbr}/sessions`, order: 6, condition: 'Conference' },
      { label: "Registration", to: `/${showAbbr}/registration`, order: 7, condition: 'AttendeeRegistration' },
      { label: "My Account", to: `${showAbbr}/invoice#Account`, order: 8, condition: 'isExhibitorUser' },
    ]
  }
  // Add other show-specific menus here as needed
};

// export const showMenus = {
//   wce24: [
//     { label: 'Speakers', link: 'https://dev-kcmaorg2.pantheonsite.io/event-microsite/keynotes' },
//     { label: 'Tours', link: 'https://dev-kcmaorg2.pantheonsite.io/event-microsite/2024-fall-conference-tours' },
//     { label: 'Hotel', link: 'https://dev-kcmaorg2.pantheonsite.io/event-microsite/hotel' },
//   ],
// };
// export const labelOverrides = {
//   wce24: {
//     attendees: "Who’s Coming",
//     sessions: "Agenda",
//   },
// };